<nz-layout *ngIf="isAuthenticated" dir="rtl" class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <img src="/assets/tktahlogo.svg" alt="logo" />
    </div>
    <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item [routerLink]="['/users']">
        <span nz-icon nzType="user"></span>
        <span>المستخدمين</span>
      </li>
      <li nz-menu-item [routerLink]="['/ticket-providers']">
        <span nz-icon nzType="usergroup-add" nzTheme="outline"></span>
        <span>مزودي التذاكر</span>
      </li>

      <li nz-menu-item [routerLink]="['/categories']">
        <span nz-icon nzType="database" nzTheme="outline"></span>
        <span>تصنيف الفعاليات</span>
      </li>
      <li nz-menu-item [routerLink]="['/events']">
        <span nz-icon nzType="wallet" nzTheme="outline"></span>
        <span>الفعاليات</span>
      </li>

      <li nz-submenu nzTitle="مراكز الترفيه" nzIcon="ordered-list">
        <ul>
          <li nz-menu-item [routerLink]="['/entertainment']">
            <span nz-icon nzType="play-circle" nzTheme="outline"></span>
            <span>مراكز الترفيه</span>
          </li>
          <li nz-menu-item [routerLink]="['/entertainment-alhokair']">
            <span nz-icon nzType="play-circle" nzTheme="outline"></span>
            <span>مركز ترفيه الحكير</span>
          </li>
        </ul>
      </li>

      <li nz-submenu nzTitle="المسافر" nzIcon="ordered-list">
        <ul>
          <li nz-menu-item [routerLink]="['/almosafer/activities']">
            <span>الأنشطة</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/orders']">
            <span>الطلبات</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/categories']">
            <span>الاصناف</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/cities']">
            <span>المدن</span>
          </li>
          <li nz-menu-item [routerLink]="['/almosafer/icons']">
            <span>الايقونات</span>
          </li>
        </ul>
      </li>
      <li nz-menu-item [routerLink]="['/notification']">
        <span nz-icon nzType="notification" nzTheme="outline"></span>
        <span>الإشعارات</span>
      </li>
      <li nz-menu-item [routerLink]="['/cities']">
        <span nz-icon nzType="build" nzTheme="outline"></span>
        <span>المدن</span>
      </li>
      <li nz-menu-item [routerLink]="['/provinces']">
        <span nz-icon nzType="build" nzTheme="outline"></span>
        <span>المناطق</span>
      </li>
      <li nz-menu-item [routerLink]="['/locations']">
        <span nz-icon nzType="global" nzTheme="outline"></span>
        <span>المواقع</span>
      </li>
      <li nz-menu-item [routerLink]="['/informative-icons']">
        <span nz-icon nzType="file-image" nzTheme="outline"></span>
        <span>ايقونات اعلامية</span>
      </li>
      <li nz-submenu nzTitle="الطلبات" nzIcon="ordered-list">
        <ul>
          <li nz-menu-item nzSelected [routerLink]="['/requests/ticket-providers']">
            موزعي التذاكر
          </li>
          <li nz-menu-item [routerLink]="['/requests/companies-reservations']">
            حجز الشركات
          </li>
          <li nz-menu-item [routerLink]="['/requests/job-requests']">
            طلبات العمل
          </li>
        </ul>
      </li>
      <li nz-menu-item [routerLink]="['/orders']">
        <span nz-icon nzType="ordered-list" nzTheme="outline"></span>
        <span>المشتريات</span>
      </li>
      <li nz-menu-item [routerLink]="['/tickets']">
        <span nz-icon nzType="snippets" nzTheme="outline"></span>
        <span>التذاكر</span>
      </li>
      <li nz-menu-item [routerLink]="['/events-sections']">
        <span nz-icon nzType="partition" nzTheme="outline"></span>
        <span>اقسام الفعاليات</span>
      </li>
      <li nz-menu-item [routerLink]="['/banners']">
        <span nz-icon nzType="picture" nzTheme="outline"></span>
        <span>البانرز</span>
      </li>
      <li nz-menu-item [routerLink]="['/newsletter']">
        <span nz-icon nzType="read" nzTheme="outline"></span>
        <span>النشرة البريدية</span>
      </li>
      <li nz-menu-item (click)="logout()">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        <span>تسجيل الخروج</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout *ngIf="!isAuthenticated">
  <nz-content>
    <div>
      <router-outlet></router-outlet>
    </div>
  </nz-content>
</nz-layout>
<app-spinner></app-spinner>