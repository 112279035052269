import { AnonymousGuard } from './services/anonymous-guard.service';
import { AuthGuard } from './services/auth.guard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/users/list' },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [AnonymousGuard] },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'requests', loadChildren: () => import('./pages/requests/requests.module').then(m => m.RequestsModule), canActivate: [AuthGuard] },
  { path: 'newsletter', loadChildren: () => import('./pages/newsletter/newsletter.module').then(m => m.NewsletterModule), canActivate: [AuthGuard] },
  { path: 'photos', loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosModule), canActivate: [AuthGuard] },
  { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule), canActivate: [AuthGuard] },
  { path: 'events', loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule), canActivate: [AuthGuard] },
  { path: 'categories', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthGuard] },
  { path: 'cities', loadChildren: () => import('./pages/cities/cities.module').then(m => m.CitiesModule), canActivate: [AuthGuard] },
  { path: 'provinces', loadChildren: () => import('./pages/provinces/provinces.module').then(m => m.ProvincesModule), canActivate: [AuthGuard] },
  { path: 'ticket-providers', loadChildren: () => import('./pages/ticket-providers/ticket-providers.module').then(m => m.TicketProvidersModule), canActivate: [AuthGuard] },
  { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
  { path: 'tickets', loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsModule), canActivate: [AuthGuard] },
  { path: 'events-sections', loadChildren: () => import('./pages/events-sections/events-sections.module').then(m => m.EventsSectionsModule), canActivate: [AuthGuard] },
  { path: 'banners', loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersModule), canActivate: [AuthGuard] },
  { path: 'locations', loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule), canActivate: [AuthGuard] },
  { path: 'informative-icons', loadChildren: () => import('./pages/informative-icon/informative-icon.module').then(m => m.InformativeIconModule), canActivate: [AuthGuard] },
  { path: 'entertainment', loadChildren: () => import('./pages/entertainment/entertainment.module').then(m => m.EntertainmentModule), canActivate: [AuthGuard] },
  { path: 'entertainment-alhokair', loadChildren: () => import('./pages/alhokair-entertainment/alhokair-entertainment.module').then(m => m.AlhokairEntertainmentModule), canActivate: [AuthGuard] },
  { path: 'notification', loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule), canActivate: [AuthGuard] },
  { path: 'almosafer', loadChildren: () => import('./pages/almosafer/almosafer.module').then(m => m.AlmosaferModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
